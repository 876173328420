import { Grid, SvgIcon, useTheme, } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { usePageContext } from "../Context/PageContextProvider";
import { useHistory } from "react-router-dom";
import clsx from "clsx";
import { useStyles } from "./SearchBox.style";
const SearchBox = (props) => {
    const theme = useTheme();
    const [isOpen, setIsOpen] = useState(props.open ?? false);
    const classes = useStyles();
    const history = useHistory();
    const [inputValue, setInputValue] = useState(props.inputQuery ?? "");
    // const [suggestions, setSuggestions] = useState([]);
    const { startPageData } = usePageContext();
    const searchRef = useRef(null);
    useEffect(() => {
        if (props?.inputQuery) {
            setInputValue(props.inputQuery);
        }
    }, [props.inputQuery]);
    useEffect(() => {
        isOpen ? props.onSearchBoxOpen(isOpen) : props.onSearchBoxClose(isOpen);
        isOpen && searchRef?.current && searchRef.current.focus();
    }, [isOpen]);
    function goToSearchResultsPage() {
        let pathToSearchResultsPage = "/sokresultat";
        if (startPageData &&
            startPageData.searchResultsPageReference &&
            startPageData.searchResultsPageReference.value) {
            const absolutePath = startPageData.searchResultsPageReference.value.toString();
            pathToSearchResultsPage = getSearchResultsPath(absolutePath);
        }
        function getSearchResultsPath(absolutePath) {
            const actualPathToSearchResultsPage = new URL(absolutePath).pathname;
            let webshopPath = pathToSearchResultsPage !== actualPathToSearchResultsPage
                ? actualPathToSearchResultsPage
                : pathToSearchResultsPage;
            // Ensure the search query is URL-safe
            const encodedSearchQuery = encodeURIComponent(inputValue);
            webshopPath += `?q=${encodedSearchQuery}`;
            return webshopPath;
        }
        history.push(pathToSearchResultsPage);
    }
    const handleCloseIconClick = () => {
        // Clear the search query
        setInputValue("");
        setIsOpen(false);
    };
    const handleEnterKeyDown = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            const inputValue = event.target.value;
            setInputValue(inputValue);
            goToSearchResultsPage();
        }
    };
    const handleInputChange = (event, newInputValue) => {
        setInputValue(newInputValue);
        // fetchAutocompleteOptions(newInputValue);
    };
    // const fetchAutocompleteOptions = async (inputValue: string) => {
    //   if (!inputValue || inputValue.length < 3) {
    //     setSuggestions([]);
    //     return;
    //   }
    //   const response = await FetchAutocompleteSuggestions(inputValue);
    //   const data = await response.json();
    //   setSuggestions(data.suggestions);
    // };
    const hybridInput = isOpen
        ? React.createElement("input", { type: "search", placeholder: props.placeholder, className: classes.input, onKeyDown: (event) => {
                handleEnterKeyDown(event);
            }, ref: searchRef, onChange: (event) => handleInputChange(event, event.target.value), value: inputValue })
        : React.createElement("div", { className: classes.placeholderPrompt, onClick: () => setIsOpen(true) }, props.placeholder);
    return (React.createElement(Grid, { container: true, className: clsx(classes.searchBoxContainer, isOpen && classes.searchBoxContainerWide) },
        React.createElement(SvgIcon, { onClick: () => setIsOpen(true), className: classes.searchIcon }, theme.icons?.searchIcon),
        hybridInput,
        isOpen && !props.hideCloseIcon &&
            React.createElement(SvgIcon, { onClick: handleCloseIconClick, className: classes.closeIcon }, theme.icons?.closeIcon)));
};
export default SearchBox;
